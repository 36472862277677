@tailwind base;
@tailwind components;
@tailwind utilities;

@import '../../node_modules/swiper/swiper-bundle.css';
@import '../../node_modules/glightbox/dist/css/glightbox.css';
@import 'aos/dist/aos.css';

.group:hover * .group-hover\:w-full {
	width: 100%;
}

.masonry {
	column-count: 2;
  	column-gap: 10px;
}

@media (min-width: 768px) {
	.masonry {
		column-count: 3;
	}
}

@media (min-width: 1024px) {
	.masonry {
		column-count: 4;
	}
}

.masonry div {
	display: grid;
	grid-template-rows: 1fr auto;
	break-inside: avoid;
	margin-bottom: 10px;
	cursor: pointer;
}

.masonry img {
	grid-row: 1 / -1;
	grid-column: 1;
}

/* CALENDAR */

::-webkit-scrollbar {
	width: 0px;
  }
::-webkit-scrollbar-track {
	display: none;
}
.custom-calendar.vc-container {
	border-radius: 0;
}
  .vc-container.vc-is-expanded {
    min-width: 0 !important;
	border: none;
}
.vc-pane {
	min-width: 0 !important;
}
.vc-header {
	background-color: theme('colors.gray.200');
	padding: 10px !important;
	width: auto;
}

.vc-weekday {
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	height: 60px !important;
	width: 60px !important;
}

.vc-weeks {
	padding: 0 !important;
	min-width: 0 !important;
    grid-template-columns: repeat(7, 60px) !important;
	gap: 3px;
}

.vc-day {
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	height: 60px !important;
	width: 60px !important;
}

.vc-arrow:hover {
    background: theme('colors.gray.100') !important;
}

@media (max-width: 640px)
{
	.vc-weekday {
		height: 30px !important;
		width: 30px !important;
	}

	.vc-day {
		height: 30px !important;
		width: 30px !important;
	}

	.vc-weeks {
		grid-template-columns: repeat(7,30px)!important;
	}
}

.filepond--item {
    width: 190px !important;
    height: 123px !important;
}

.filepond--list {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -0.5em;
}

/* FIX SWIPER HERO SLIDER BUG */
.swiper-slide{
    -webkit-backface-visibility: hidden;
    -webkit-transform: translate3d(0,0,0);
}
.swiper-wrapper{
    -webkit-transform-style: preserve-3d;
}
